.card {
    /* padding between cards */
    border-radius: 10px;
    /* shrink everything in the card width */
    overflow: hidden;
    box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.75);
    /* works with hover, makes it smooth transition */
    transition: transform 150ms ease-in;
    cursor: pointer;
    /* width: min(40%, 500px, 100%); */
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 500px;
}

.card:hover{
    transform: scale(1.03);
}
.img {
    object-fit:cover;
    height: 450px;
    width: 100%;
}
.card_info {
    /* removes default top padding of text info */
    margin-top: -9px; 
    border-radius: 10px;
    padding: 20px;
    border: 1;
}
.title{
    margin-top: 10px;
    font-size: 24px;
    font-weight: 600;
}
.desc {
    font-size: 18px;
    font-weight: 300;
    margin-top: 8px;
    margin-bottom: 8px;
}
.date {
    font-size: 14px;
    color:gray;
}
@media screen and (max-width: 430px) {
    .img {
        object-fit:cover;
        height: 350px;
        width: 100%;
    }
    .title{
        margin-top: 10px;
        font-size: 20px;
    }
    .desc {
        font-size: 15px;
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .date {
        font-size: 12px;
        color:gray;
    }
}