.container1 {
  padding-left: 40px;
  padding-right: 70px;
}
.container {
  height: 250px;
  background-color: #a085ff;
  text-align: center;
  padding-top: 90px;
}
.p {
  color: white;
  font-weight: 700;
  font-size: 70pt;
  line-height: 50px;
}
.p span {
  font-size: 15pt;
  font-weight: 400;
  color: black;
}
.reactIcon {
  color: #61dafb;
  font-size: 30px;
}
.btsIcon {
  color: #5b4282;
  font-size: 30px;
}
.jsIcon {
  color: #a3d968;
  font-size: 30px;
}
.angularIcon {
  color: #de0031;
  font-size: 40px;
}
.ionicIcon {
  color: #0e6dfd;
  font-size: 35px;
}
.sqlIcon {
  color: #1581c9;
  font-size: 35px;
}
.theProject {
  padding-left: 100px;
  padding-right: 40px;
  padding-top: 80px;
  padding-bottom: 80px;
}
.Sourceicon {
  font-size: 35px;
}
.infoIcon {
  color: black;
  font-size: 35px;
}
.infoBtn:focus {
  box-shadow: none !important;
}
.infoIcon:hover {
  cursor: pointer;
}
.devices {
  height: 300px;
}
.mobdevices {
  height: 400px;
}
.desc {
  font-size: 20px;
}
.head {
  padding-bottom: 40px;
  text-align: center;
  font-weight: 700;
  color: #a085ff;
}
.hr {
  border-top: 2px solid #a085ff;
  margin-left: 80px;
}

.projectNavLink:focus {
  box-shadow: none !important;
}
.here:hover {
  cursor: pointer;
}
.projectNavLink.active {
  font-size: 17pt;
  text-decoration: none;
  color: black;
  font-weight: 700;
}
.projectNavLink.inactive {
  font-size: 14pt;
  text-decoration: none;
  color: black;
  opacity: 0.5;
}
.h1experience {
  padding-top: 40px;
  padding-bottom: 50px;
  text-align: center;
  font-weight: 700;
  color: black;
  font-size: 50px;
}
.h1experience::after {
  content: "";
  display: flex;
  margin: 0 auto;
  width: 70px;
  padding-top: 3px;
  border-bottom: 3px solid #a085ff;
}
.experienceContainer {
  width: 60%;
  margin: 0 auto;
}
.dateCol {
  text-align: end;
}
.experienceContainer h2,
h4 {
  color: #a085ff;
  font-size: 28px;
}
.experienceContainer h3 {
  font-size: 20px;
}
.experienceContainer li {
    font-size: 18px;
    color: #474747;
    margin-left: 15px;
  }
.row {
    margin-bottom: 20px;
}
.blackIcon {
  color: black;
  font-size: 40px;
}
@media screen and (max-width: 430px) {
  .container {
    height: 200px;
  }
  .container1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .p {
    font-size: 40pt;
    line-height: 40px;
  }
  .devices {
    padding-top: 15px;
    height: 200px;
  }
  .mobdevices {
    padding-top: 15px;
    height: 300px;
    padding-left: 20px;
  }
  .theProject {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .head {
    padding-bottom: 20px;
  }
  .hr {
    margin-left: 0px;
  }
  .h1experience {
    font-size: 30px;
    padding-bottom: 30px;
  }
  .h1experience::after {
    width: 40px;
  }
  .experienceContainer {
    width: 100%;
  }
  .experienceContainer h2 {
    font-size: 22px;
  }
  .experienceContainer h4 {
    font-size: 18px;
  }
  .experienceContainer h3 {
    font-size: 18px;
  }
  .experienceContainer li {
      font-size: 17px;
    }
  .row {
      margin-bottom: 20px;
  }
}
