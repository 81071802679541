.icon:hover {
  cursor: pointer;
}
.icon {
  color: white;
  font-size: 35px;
}
footer {
  background-color: #a085ff;
  text-align: center;
}
.fluid {
  padding-top: 70px;
  background-color: #a085ff;
  text-align: center;
}
.contactSection {
  margin: 0 auto;
  width: 25%;
  padding-bottom: 10px;
}

.reactIcon {
  color: #61dafb;
  font-size: 30px;
}
.heading {
    color: white;
    font-weight: 700;
    font-size: 35pt;
}
@media screen and (max-width: 430px) {
    .contactSection {
        width: 80%;
        font-size: 14pt;
      }
    .heading {
        font-size: 30pt;
    }
}
