.pfp {
  height: 450px;
}
.pfpClm {
  text-align: center;
}
.container {
  padding-top: 150px;
  padding-bottom: 120px;
}
.name {
  font-weight: 700;
  color: #a085ff;
  font-size: 70pt;
}
.roleClm {
  font-size: 30pt;
}
.container span {
  color: #0e6dfd;
}
.quote {
  font-style: italic;
  font-size: 14pt;
}
.viewProjBtn:focus {
  box-shadow: none !important;
}
@media screen and (max-width: 430px) {
  .container {
    padding-top: 100px;
  }
  .pfp {
    padding-top: 50px;
    height: 360px;
  }
  .name {
    font-size: 50pt;
  }
  .roleClm {
    font-size: 18pt;
  }
  .viewProjBtn {
    width: 170px;
    height: 50px;
  }
}
