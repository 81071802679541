.container {
  height: 250px;
  background-color: #a085ff;
  text-align: center;
  padding: 90px 0;
}
.p {
  color: white;
  font-weight: 700;
  font-size: 70pt;
  line-height: 50px;
}
.p span {
  font-size: 15pt;
  font-weight: 400;
  color: black;
}
.container1 {
  padding-top: 80px;
}
.whoamiClm {
  text-align: center;
}
.aboutpfp {
  height: 450px;
}
.intro {
  font-size: 14pt;
}
.row {
  padding-bottom: 70px;
}
.skills {
  text-align: center;
  width: 45%;
  padding-bottom: 50px;
  padding-top: 30px;
}
.reactIcon {
  color: #61dafb;
  font-size: 40px;
}
.jsIcon {
  color: #a3d968;
  font-size: 40px;
}
.angularIcon {
  color: #de0031;
  font-size: 45px;
}
.ionicIcon {
  color: #0e6dfd;
  font-size: 40px;
}
.blueIcon {
  color: #3193d3;
  font-size: 40px;
}
.sqlIcon {
  color: #1581c9;
  font-size: 45px;
}
.htmlIcon {
  color: #dd4b25;
  font-size: 45px;
}
.blackIcon {
  color: black;
  font-size: 40px;
}
h1 span {
  font-weight: 700;
  color: #a085ff;
}
.here:hover {
  cursor: pointer;
}
.hr {
  border-top: 2px solid #a085ff;
}
.about_activities {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  justify-content: space-around;
  padding: 20px;
  margin-top: 30px;
}
@media screen and (max-width: 430px) {
  .container {
    height: 200px;
  }
  .container1 {
    padding-top: 50px;
  }
  .row {
    padding-left: 10px;
    padding-right: 10px;
  }
  .p {
    font-size: 40pt;
    line-height: 40px;
  }
  .aboutpfp {
    height: 340px;
  }

  .reactIcon {
    color: #61dafb;
    font-size: 30px;
  }
  .jsIcon {
    color: #a3d968;
    font-size: 30px;
  }
  .angularIcon {
    color: #de0031;
    font-size: 35px;
  }
  .ionicIcon {
    color: #0e6dfd;
    font-size: 35px;
  }
  .blueIcon {
    color: #3193d3;
    font-size: 30px;
  }
  .sqlIcon {
    color: #1581c9;
    font-size: 35px;
  }
  .htmlIcon {
    color: #dd4b25;
    font-size: 35px;
  }
  .blackIcon {
    color: black;
    font-size: 30px;
  }
  .skills {
    padding-bottom: 20px;
  }
  .about_activities {
    margin-left: 0px;
  }
}
