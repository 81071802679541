.container {
  padding-top: 30px;
}
.resume {
  width: 250px;
  height: 60px;
  background-color: #a085ff;
  border-radius:25pt;
  border: none;
  padding: 0 10px;
  color: white;
  font-size: 14pt;
  display: flex;
  align-items: center;
}
.linkedin {
  width: 250px;
  height: 60px;
  background-color: #0e6dfd;
  border-radius: 25pt;
  border: none;
  padding: 0 10px;
  color: white;
  font-size: 14pt;
  display: flex;
  align-items: center;
}
.github {
  width: 250px;
  height: 60px;
  background-color: black;
  border-radius: 25pt;
  border: none;
  padding: 0 10px;
  color: white;
  font-size: 14pt;
  display: flex;
  align-items: center;
}
.icon {
  color: black;
  height: 45px;
  width: 45px;
  background-color: white;
  border-radius: 50pt;
  float: left;
  padding: 0 7px;
}
.LinkedInicon {
  color: #0e6dfd;
  height: 45px;
  width: 45px;
  background-color: white;
  border-radius: 50pt;
  float: left;
  padding: 0 12px;
}
.msgIcon {
  color: #f848e9;
  height: 45px;
  width: 45px;
  background-color: white;
  border-radius: 50pt;
  float: left;
  padding: 0 7px;
}
.textbtn {
  padding-left: 22px;
  font-weight: 700;
  font-size: 15pt;
}
.email {
  width: 250px;
  height: 60px;
  background-color: #f848e9;
  border-radius: 25pt;
  border: none;
  padding: 0 10px;
  color: white;
  font-size: 14pt;
  display: flex;
  align-items: center;
}
.sideNavfooter {
font-size: 16pt;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}
.a {
  color: white;
  text-decoration: none;
}
.a:focus {
  color: white;
}
